import styled from 'styled-components'

interface DetailnavInterface {
  hasSlug: boolean
}

export const DetailNav = styled.nav.attrs({
  className:
    'font-bold text-center text-base h-14 lg:h-20 fixed -top-0 w-full bg-primaryWhite flex py-3 lg:py-5 px-6 lg:px-20 justify-between items-center'
})<DetailnavInterface>`
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.15));
  z-index: 10;
  @media only screen and (max-width: 1024px) {
    display: ${(props) => (props.hasSlug ? 'flex' : 'none !important')};
  }
`
