import config from 'api/config'
import axios from 'axios'
import MAIN_URL from 'api/config'

export default {
  getFetchTask: async () => {
    const url = `${MAIN_URL}/api/task`

    let response = await axios.get(url)

    return response.data.content.allTask
  },

  uploadLinkSubmission: async (id: any, isEditable: boolean, event: any) => {
    if (isEditable) {
      return axios.put(`${MAIN_URL}/api/task/submission/${id}`, event)
    } else {
      return axios.post(`${MAIN_URL}/api/task/${id}/submission`, event)
    }
  }

  ,
  uploadFileSubmission: async (file: any, id: any, submissions: any) => {
    const res = await axios.get(`${config}/api/common/generate-put-url`, {
      params: {
        file_name: file?.name,
        fileAWSType: file?.type
      }
    })

    const file_aws_key = res.data.content[0].file_aws_key
    const preSignedUrl = res.data.content[1].presigned_url

    const customAxios = axios.create()
    let formData = new FormData()
    formData.append('file', file)
    const uploadRes = await customAxios.put(preSignedUrl, formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })

    let mediaUrl = {
      status: 0
    }

    if (submissions?.length  > 0) {
      const postUploadResponse = await axios.put(
        `${config}/api/task/submission/${submissions}`,
        {
          fileAWSKey: file_aws_key
        },
        {
          headers: {
            'content-type': 'application/json'
          }
        }
      )
      mediaUrl = postUploadResponse.data.content
    } else {
      const postUploadResponse = await axios.post(
        `${config}/api/task/${id}/submission`,
        {
          fileAWSKey: file_aws_key
        },
        {
          headers: {
            'content-type': 'application/json'
          }
        }
      )
      mediaUrl = postUploadResponse.data.content
    }

    return mediaUrl
  },

  getUnsubmittedTask: async () => {
    const response = await axios.get(`${config}/api/task`)
    return response.data.content.unsubmittedTask
  }
}
