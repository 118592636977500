import config from 'api/config'
import axios from 'axios'

interface MatchedResponse {
  username: string
  content: IMabaInfo[]
  message: string
}

export default {
  getRecomend: async (page: number): Promise<MabaAttributes[]> => {
    const res = await axios.get(
      `${config}/api/interest/recomendation?page=${page}`
    )
    return res.data.users
  },

  createInvitation: async (
    username: string
  ): Promise<{ [key: string]: string }> => {
    const res = await axios.post(`${config}/api/invitation/create_invitation`, {
      username: username
    })
    return res.data
  },

  getMatched: async (page: number): Promise<MatchedResponse> => {
    const res = await axios.get(`${config}/api/senior?page=${page}&limit=10`)

    const response = {
      username: res.data.username,
      content: res.data.content.allSeniorReview,
      message: res.data.message
    }
    return response
  },

  actionMatched: async (
    data: { [key: string]: string },
    id: string
  ): Promise<any> => {
    const res = await axios.put(`${config}/api/senior/${id}`, data)
    return res.data
  }
}
