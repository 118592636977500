import axios from 'axios'
import config from 'api/config'

export default {
  getProfile: async (username: any) => {
    const res = await axios.get(`${config}/api/profile/${username}`)
    return res.data
  },

  inviteFriends: async (friendUsername: any) => {
    const res = await axios.post(`${config}/api/invitation/create_invitation`, {
      username: friendUsername
    })
    return res
  }
}
