import { createSlice } from '@reduxjs/toolkit'

const profile = createSlice({
  name: 'profile',
  initialState: {
    profile: {
      username: '',
      nama_lengkap: '',
      nama_panggilan: '',
      tempat_lahir: '',
      tanggal_lahir: '',
      jenis_kelamin: '',
      jurusan: '',
      angkatan: '',
      asal_sekolah: '',
      id_line: '',
      instagram: '',
      bio: '',
      foto_profil: '',
      foto_profil_type: '',
      User_Interest: []
    },
    loading: false,
    error: null,
    messages: []
  },
  reducers: {
    setProfile(state, { payload }) {
      state.profile = payload
    },
    setProfilePicture(state, { payload }) {
      state.profile.foto_profil = payload
    },
    loadingProfile(state) {
      state.loading = !state.loading
      return state
    },
    setError(state, { payload }) {
      state.error = payload
    },
    setMessages(state, { payload }) {
      state.messages = payload
    }
  }
})

export const {
  setProfile,
  loadingProfile,
  setError,
  setProfilePicture,
  setMessages
} = profile.actions

export default profile.reducer
