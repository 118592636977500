import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistStore } from 'redux-persist';

import reducer from './reducers';
import logger from './middlewares/logger';
import services from 'api/services';

const middleware = [
    ...getDefaultMiddleware({
        thunk: { extraArgument: { services } },
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
    })
];
if (process.env.NODE_ENV === 'development') middleware.push(logger);

export default function useStore() {
    const store = configureStore({
        reducer,
        middleware,
        devTools: process.env.NODE_ENV === 'development'
    });

    const persistor = persistStore(store);
    return { store, persistor };
}
