import {
  setAccount,
  setToken,
  loadingAccount,
  clearToken,
  clearAccount,
  setNewAccount,
  updateAccount
} from '.'
import Router from 'next/router'
import api from 'api/services'

export const login = (data: SsoData) => {
  return (dispatch: any, _getState: any) => {
    try {
      dispatch(loadingAccount())
      dispatch(setToken(data.token))
      console.log(data)
      dispatch(setAccount(data.user.user))
      dispatch(loadingAccount())

      if (
        data.user.isNewUser ||
        (data.user.user.User_Interest && data.user.user.User_Interest === [])
      ) {
        Router.push('/onboarding')
      }

      Router.push('/home')
    } catch (error) {
      dispatch(loadingAccount())
      console.log(error)
    }
  }
}

export const logout = () => {
  return (dispatch: any, _getState: any) => {
    api.sso.redirectToSSOLogout()
    dispatch(clearAccount())
    dispatch(clearToken())
  }
}

export const updateProfile = (
  data: ProfileData,
  callback: () => void,
  errorCallback: () => void
) => {
  return async (dispatch: any, _getState: any) => {
    try {
      dispatch(loadingAccount())
      const res = await api.profile.updateProfile(data)

      dispatch(setAccount(res))
      dispatch(loadingAccount())

      callback()
    } catch (error) {
      dispatch(loadingAccount())
      console.log(error)
      errorCallback()
    }
  }
}

export const editProfile = (
  data: ProfileData,
  withPhoto: boolean,
  callback: () => void
) => {
  return async (dispatch: any, _getState: any) => {
    try {
      dispatch(loadingAccount())
      let res = await api.profile.editProfile(data, true)

      dispatch(updateAccount(res))
      dispatch(loadingAccount())

      callback()
    } catch (error) {
      console.log(error)
    }
  }
}

export const uploadProfilePicture = (data: File) => {
  return async (dispatch: any, _getState: any) => {
    try {
      dispatch(loadingAccount())
      const res = await api.profile.uploadProfilePicture(data)

      dispatch(updateAccount({ foto_profil: res }))
      dispatch(loadingAccount())
    } catch (error) {
      dispatch(loadingAccount())
      console.log(error)
    }
  }
}
