export const NAVBAR = [
  {
    page: 'Homepage',
    setPage: '',
    link: '/home'
  },
  {
    page: 'Friends',
    setPage: 'Friends',
    link: '/friends'
  },
  {
    page: 'Tasks',
    setPage: 'Tasks',
    link: '/task'
  },
  {
    page: 'Events',
    setPage: 'Events',
    link: '/events'
  },
  {
    page: 'About Fasilkom/UI',
    setPage: 'About',
    link: '/about'
  }
]
