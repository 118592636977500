import { createSelector } from '@reduxjs/toolkit'

// TODO
export const isMaba = (state: { auth: { account: ProfileData } }) => {
  if (state.auth.account?.angkatan === '2022') {
    return true
  }

  return false
}

export const getAuthUser = (state: { auth: { account: UserData } }) =>
  state.auth.account

export const getUserToken = (state: { auth: { token: any } }) =>
  state.auth.token

export const isLoading = (state: { auth: { loading: any } }) =>
  state.auth.loading

export const getNewAccount = (state: { auth: { newAccount: any } }) =>
  state.auth.newAccount

export const isLoggedIn = createSelector(getUserToken, (token) => !!token)

export const getProfileData = createSelector(getAuthUser, (user) => {
  let account = user

  if (!account?.username) {
    account = JSON.parse(window.localStorage.getItem('account') as string)
  }

  const profile: ProfileData = {
    username: account?.username,
    nama_lengkap: account?.nama_lengkap,
    nama_panggilan: account?.nama_panggilan,
    tempat_lahir: account?.tempat_lahir,
    tanggal_lahir: account?.tanggal_lahir,
    jenis_kelamin: account?.jenis_kelamin,
    angkatan: account?.angkatan,
    jurusan: account?.jurusan,
    asal_sekolah: account?.asal_sekolah,
    id_line: account?.id_line,
    instagram: account?.instagram,
    bio: account?.bio,
    foto_profil: account?.foto_profil,
    foto_profil_type: account?.foto_profil_type,
    User_Interest: account?.User_Interest ?? [],
    domicile: account?.domicile,
    whatsapp: account?.whatsapp
  }
  return profile
})
