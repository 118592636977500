import React from 'react'
import { DetailNav } from './style'
import { useRouter } from 'next/router'

interface DetailsNavbarInterface {
  hasSlug: boolean
  title: string
}

const DetailsNavbar: React.FC<DetailsNavbarInterface> = ({
  hasSlug,
  title
}) => {
  const router = useRouter()

  return (
    <DetailNav hasSlug={hasSlug}>
      <div onClick={router.back} className={'cursor-pointer absolute'}>
        <img src={`/images/icons/arrow_back.svg`} />
      </div>
      <div className={'w-full'}>{title}</div>
    </DetailNav>
  )
}

export default DetailsNavbar
