import axios from 'axios'
import config from 'api/config'

export default {
  getFetchSeniorsDetail: async (id: string | string[] | undefined) => {
    const url = `${config}/api/senior/${id}`
    let response = await axios.get(url)
    return response.data.content
  },
  putMabaToSeniorInvMessage: async (id: string, payload: { message: string }) => {
    const url = `${config}/api/senior/${id}`
    const response = await axios.put(url, payload)
    return response.data
  }
}