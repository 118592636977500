import config from 'api/config'
import axios from 'axios'

export default {
  getToken: async (data: any) => {
    const amount = { token_amount: data }
    const response = await axios.post(`${config}/api/token/generate`, amount)

    const res = {
      token: response.data.token,
      expiredAt: response.data.expiredAt
    }
    return res
  },

  checkToken: async () => {
    const response = await axios.get(`${config}/api/token/check`)
    const res = {
      token: response.data.token,
      expiredAt: response.data.expiredAt
    }
    return res
  },

  sendToken: async (data: any) => {
    const response = await axios.post(`${config}/api/token/post`, data)
    return response.data
  }
}
