import { createSlice } from '@reduxjs/toolkit'

const task = createSlice({
    name: 'task',
    initialState: {
        tasks: [
            
        ],
    
    },
    reducers: {
        setTask(state, { payload }) {
            state.tasks = payload
        },
        setSubmission(state, {payload}) {

        }
    }
})

export const {setTask} = task.actions

export default task.reducer
