import { createStandaloneToast, ToastPosition } from '@chakra-ui/react'

const toast = createStandaloneToast()
// const customToast = createStandaloneToast({ theme: yourCustomTheme })

export const SuccessToast = (
  text: string,
  position: ToastPosition = 'bottom'
) =>
  toast({
    position: position,
    description: text,
    status: 'success',
    duration: 5000,
    isClosable: true
  })
export const FailToast = (text: string, position: ToastPosition = 'bottom') =>
  toast({
    position: position,
    description: text,
    status: 'error',
    duration: 5000,
    isClosable: true
  })
export const InfoToast = (text: string, position: ToastPosition = 'bottom') =>
  toast({
    position: position,
    description: text,
    status: 'info',
    duration: 5000,
    isClosable: true
  })
