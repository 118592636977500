import axios from 'axios'
import config from 'api/config';

export default {
  getFetchReceivedInvitations: async (page: number) => {
    const url = `${config}/api/invitation/received_list?page=${page}&limit=10`

    let response = await axios.get(url)

    return response.data.content
  },

  getFetchSentInvitations: async (page: number) => {
    const url = `${config}/api/invitation/sent_list?page=${page}&limit=10`

    let response = await axios.get(url)

    return response.data.content
  },

  getFetchInvitationDetailProfile: async (friend: string | string[] | undefined) => {
    const url = `${config}/api/invitation/invitation_request/${friend}`
    let response = await axios.get(url)
    return response.data
  },

  postAcceptInvitations: async (user: any) => {
    const url = `${config}/api/invitation/accept_invitation`

    let response = await axios.post(
      url,
      {
        "username": user
      }
    )
    return response.data.content
  }
}