import React from 'react'
import api from 'api/services'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { NAVBAR } from './constNavbar'
import {
  Nav,
  NavLinkWrapper,
  ProfileDropDown,
  NavLogoWrapper,
  WrapperHamburger,
  HamburgerIcon,
  FloatingDiv
} from './style'
import { useRouter } from 'next/router'
import { isLoggedIn, getAuthUser } from 'redux/slices/auth/selector'
import BrandLogo from './brand.logo'
import NavLink from './navlink'

interface NavbarInterface {
  hasSlug: boolean
  setShowLogoutPopup: Function
}

const Navbar: React.FC<NavbarInterface> = ({ hasSlug, setShowLogoutPopup }) => {
  const PathSlice = () => {
    let path = router.pathname
    let toReturn = ''

    if (path.includes('-')) {
      const fullPath = path.replace('/', ' ')
      const allWords = fullPath.split('-')
      allWords.forEach((word: string, i) => {
        const currWord = word.replace(' ', '')
        toReturn += currWord[0].toUpperCase()
        toReturn += currWord.slice(1, currWord.length).toLowerCase()
        if (i < allWords.length - 1) toReturn += ' '
      })
    } else {
      toReturn += path.slice(1, 2).toUpperCase()
      toReturn += path.slice(2, path.length).toLowerCase()
    }
    return toReturn
  }

  const loggedIn = useSelector(isLoggedIn)
  const [openArrow, setOpenArrow] = useState(false)
  const [open, setOpen] = useState(false)
  const router = useRouter()
  const [currentPage, setCurrentPage] = useState(PathSlice)
  const user = useSelector(getAuthUser)
  const batch = user?.angkatan
  const name = user?.nama_lengkap ?? user?.username

  const toggleHamburger = () => {
    setOpen(!open)
  }

  const onShowLogout = () => {
    setShowLogoutPopup(true)
  }

  const floatingDiv = openArrow && (
    <FloatingDiv openArrow={openArrow}>
      <NavLink closeNav={toggleHamburger} href="/profile">
        <span onClick={() => setOpenArrow(false)} className="flex py-4 px-8">
          <img
            className="mr-4"
            src={`/images/icons/work_24px${
              router.pathname == '/profile' ? '_purple' : ''
            }.svg`}
            alt="dashboard logo"
          />
          Profile
        </span>
      </NavLink>

      <NavLink closeNav={toggleHamburger} href="">
        <span
          onClick={onShowLogout}
          className="text-stateError block lg:border-t border-t-0 py-4 px-8"
        >
          Sign Out
        </span>
      </NavLink>
    </FloatingDiv>
  )

  return (
    <Nav hasSlug={hasSlug}>
      <NavLogoWrapper>
        <NavLink href="/home" closeNav={open && toggleHamburger} LogoNav>
          <BrandLogo />
        </NavLink>
        <div className={'lg:hidden font-bold text-primaryBlackurrant'}>
          {currentPage}
        </div>
        <WrapperHamburger open={open} onClick={toggleHamburger}>
          <HamburgerIcon />
        </WrapperHamburger>
      </NavLogoWrapper>
      <NavLinkWrapper open={open}>
        {NAVBAR.map((nav, index) => {
          return nav.page === 'Tasks' && batch !== '2022' ? (
            <div key={index} />
          ) : (
            <NavLink
              key={index}
              closeNav={toggleHamburger}
              href={`${nav.link}`}
            >
              <div onClick={() => setCurrentPage(`${nav.setPage}`)}>
                {nav.page}
              </div>
            </NavLink>
          )
        })}
        {loggedIn ? (
          <>
            <ProfileDropDown
              openArrow={openArrow}
              onClick={() => setOpenArrow(!openArrow)}
            >
              <p
                className={`inline-flex ${
                  (router.pathname == '/dashboard' ||
                    router.pathname == '/edit-profile') &&
                  'text-primaryPurple-active font-semibold'
                }`}
              >
                Welcome, {name}
                <img
                  className="ml-3 inline"
                  src="/images/icons/arrow_drop_down.svg"
                  alt="arrow"
                />
              </p>
            </ProfileDropDown>

            {floatingDiv}
          </>
        ) : (
          <ProfileDropDown onClick={api.sso.redirectToSSOLogin}>
            <p>
              <a>Sign In</a>
            </p>
          </ProfileDropDown>
        )}
      </NavLinkWrapper>
    </Nav>
  )
}

export default Navbar
