import { StyledImg } from './style'

const BrandLogo = () => {
  return (
    <span className="flex">
      <StyledImg
        src="/images/pmb.png" // Route of the image file
        alt="ristek desc"
        width="34px"
        mobileWidth="19px"
        left
      />
      <StyledImg
        src="/images/ristek.svg" // Route of the image file
        width="27px"
        mobileWidth="15px"
        alt="logo ristek"
      />
    </span>
  )
}

export default BrandLogo
