import { createSlice } from '@reduxjs/toolkit'

const auth = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    account: null,
    loading: false,
    newAccount: false
  },
  reducers: {
    setToken(state, { payload }) {
      state.token = payload
      window.localStorage.setItem('token', payload)
      return state
    },
    clearToken(state) {
      state.token = null
      window.localStorage.clear()
      return state
    },
    setAccount(state, { payload }) {
      state.account = payload
      window.localStorage.setItem('account', JSON.stringify(payload))
      return state
    },
    updateAccount(state, { payload }) {
      state.account = { ...(state.account as unknown as UserData), ...payload }
      window.localStorage.setItem('account', JSON.stringify(state.account))
      return state
    },
    clearAccount(state) {
      state.account = null
      return state
    },
    loadingAccount(state) {
      state.loading = !state.loading
      return state
    },
    setNewAccount(state) {
      state.newAccount = !state.newAccount
      return state
    }
  }
})

export const {
  setToken,
  clearToken,
  setAccount,
  clearAccount,
  loadingAccount,
  setNewAccount,
  updateAccount
} = auth.actions

export default auth.reducer
