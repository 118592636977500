import React, { useState } from 'react'
import Head from 'next/head'
import styled from 'styled-components'
import { useRouter } from 'next/router'

import Navbar from 'components/module/Navbar'
import DetailsNavbar from '../module/DetailsNavbar'
import Popup from 'components/elements/Popup'
import { logout } from 'redux/slices/auth/thunk'
import { useDispatch } from 'react-redux'

const PageWrapper = styled.div.attrs({
  className: 'bg-primaryWhite'
})<{ isUsingNavbar: boolean }>`
  ${(props) => props.isUsingNavbar && 'padding-top: 80px;'}
  min-height: 70vh;

  @media only screen and (max-width: 1024px) {
    ${(props) => props.isUsingNavbar && 'padding-top: 3.5rem;'}
  }
`

const Layout: React.FC = ({ children }) => {
  const router = useRouter()

  /**
   * To hide navbar on all the pages
   * that doesn't use navbar
   * (PAGES_WITHOUT_NAVBAR)
   */
  const PAGES_WITHOUT_NAVBAR = ['/', '/onboarding']
  const isUsingNavbar = !PAGES_WITHOUT_NAVBAR.includes(router.pathname)

  /**
   * To determine whether or not detail navbar
   * should be rendered
   */
  const hasSlug = !(
    router.pathname == '/' ||
    router.pathname == '/events' ||
    router.pathname == '/task' ||
    router.pathname == '/about' ||
    router.pathname == '/example' ||
    router.pathname == '/friends' ||
    router.pathname == '/profile' ||
    router.pathname == '/home' ||
    router.pathname == '/terms-and-conditions'
  )

  let title = ''
  if (/(events)\/+(([A-z]*[1-9]*)*)/g.test(router.pathname)) {
    title = 'Event Details'
  } else if (/(task)\/+(([A-z]*[1-9]*)*)/g.test(router.pathname)) {
    title = 'Task Details'
  } else if (/(friends)\/+(invitations)/g.test(router.pathname)) {
    title = 'Invitations'
  } else if (/(puzzle)\/+(([A-z]*[1-9]*)*)/g.test(router.pathname)) {
    title = 'Puzzle'
  } else if (/(friends)\/+(seniors)/g.test(router.pathname)) {
    title = 'Friend Details'
  } else if (/(profile)\/+(badge)/g.test(router.pathname)) {
    title = 'PMBadge'
  } else if (
    /(terms-and-conditions)\/+(([A-z]*[1-9]*)*)/g.test(router.pathname)
  ) {
    title = 'Terms and Conditions'
  }

  let headTitle = ''
  if (/(events)+((\/[A-z]*[1-9]*)*)/g.test(router.pathname)) {
    headTitle = 'Events'
  } else if (/(task)+((\/[A-z]*[1-9]*)*)/g.test(router.pathname)) {
    headTitle = 'Task'
  } else if (/(about)+((\/[A-z]*[1-9]*)*)/g.test(router.pathname)) {
    headTitle = 'About'
  } else if (/(friends)+((\/[A-z]*[1-9]*)*)/g.test(router.pathname)) {
    headTitle = 'Friends'
  } else if (/(home)+((\/[A-z]*[1-9]*)*)/g.test(router.pathname)) {
    headTitle = 'Home'
  } else if (/(dashboard)+((\/[A-z]*[1-9]*)*)/g.test(router.pathname)) {
    headTitle = 'Dashboard'
  } else if (
    /(terms-and-conditions)+((\/[A-z]*[1-9]*)*)/g.test(router.pathname)
  ) {
    headTitle = 'Terms and Conditions'
  }

  const dispatch = useDispatch()
  const [showLogoutPopup, setShowLogoutPopup] = useState<boolean>(false)
  const onLogout = () => {
    dispatch(logout())
  }

  return (
    <div>
      <Head>
        <title>{`PMB 2022 | ${headTitle}`}</title>
      </Head>

      <div className="bg-primaryAlabaster font-SFProDisplay relative">
        {isUsingNavbar && <DetailsNavbar hasSlug={hasSlug} title={title} />}
        {isUsingNavbar && (
          <Navbar hasSlug={hasSlug} setShowLogoutPopup={setShowLogoutPopup} />
        )}
        <PageWrapper isUsingNavbar={isUsingNavbar}>{children}</PageWrapper>
      </div>
      {showLogoutPopup && (
        <Popup
          cancelOnclick={() => setShowLogoutPopup(false)}
          confirmOnclick={onLogout}
        />
      )}
    </div>
  )
}

export default Layout
