import Link from 'next/link'
import { useRouter } from 'next/router'
import { StyledLink } from './style'

const NavLink: React.FC<NavLinkProps> = ({
  children,
  href,
  closeNav,
  LogoNav
}) => {
  const router = useRouter()
  

  return (
    <Link href={href} passHref>
      <StyledLink
        LogoNav={LogoNav}
        onClick={closeNav}
        active={router.asPath.includes(href)}
      >
        {children}
      </StyledLink>
    </Link>
  )
}

export default NavLink
