import axios from 'axios'
import sso from './sso'
import profile from './profile'
import invitations from './invitations'
import puzzle from './puzzle'
import task from './task'
import token from './token'
import pansos from './pansos'
import friends from './friends'
import seniors from './seniors'

axios.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem('token')

    if (token) {
      config.headers.Authorization = `JWT ${token}`
    }

    config.headers.Timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default {
  sso,
  profile,
  invitations,
  friends,
  puzzle,
  task,
  token,
  pansos,
  seniors,
}
