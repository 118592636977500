import { createSlice } from '@reduxjs/toolkit'

const senior = createSlice({
  name: 'senior',
  initialState: {
    list: [],
    seniorDetails: {
      id: '',
      message: '',
      feedback: '',
      status: '',
      nama_lengkap: '',
      nama_panggilan: '',
      tempat_lahir: '',
      tanggal_lahir: '',
      jurusan: '',
      angkatan: '',
      asal_sekolah: '',
      id_line: '',
      instagram: '',
      bio: '',
      foto_profil_key: '',
      interest: [],
    },
    error: {
      isError: false,
      message: '',
    },
    isLoading: true
  },

  reducers: {
    setSeniorDetails(state, { payload }) {
      state.seniorDetails.id = payload?.id
      state.seniorDetails.message = payload?.message
      state.seniorDetails.feedback = payload?.feedback
      state.seniorDetails.status = payload?.status
      state.seniorDetails.nama_lengkap = payload?.nama_lengkap
      state.seniorDetails.nama_panggilan = payload?.nama_panggilan
      state.seniorDetails.tempat_lahir = payload?.tempat_lahir
      state.seniorDetails.tanggal_lahir = payload?.tanggal_lahir
      state.seniorDetails.jurusan = payload?.jurusan
      state.seniorDetails.interest = payload?.interest
      state.seniorDetails.angkatan = payload?.angkatan
      state.seniorDetails.asal_sekolah = payload?.asal_sekolah
      state.seniorDetails.id_line = payload?.id_line
      state.seniorDetails.instagram = payload?.instagram
      state.seniorDetails.bio = payload?.bio
      state.seniorDetails.foto_profil_key = payload?.foto_profil_key

      return state
    },
    setError(state, { payload }) {
      state.error.isError = payload?.isError
      state.error.message = payload?.message
    },
    setLoading(state, { payload }) {
      state.isLoading = payload?.isLoading
    }
  }
})

export const { setSeniorDetails, setError, setLoading } = senior.actions

export default senior.reducer