import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import authReducer from './slices/auth'
import profileReducer from './slices/profile'
import invitationsReducer from './slices/invitations'
import taskReducer from './slices/task'
import seniorReducer from './slices/seniors'

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['account', 'token']
}

const invitationPersistConfig = {
  key: 'invitations',
  storage,
  whitelist: ['invitations']
}

const taskPersistConfig = {
  key: 'task',
  storage,
  whitelist: ['tasks']
}

export default combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  profile: profileReducer,
  invitations: invitationsReducer,
  senior: seniorReducer,
  task: persistReducer(taskPersistConfig, taskReducer),
})
