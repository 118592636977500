import axios from 'axios'
import MAIN_URL from 'api/config'

import {
  FriendRecommendation,
  SendInvitationPayload
} from 'components/container/Onboarding/match.d'
import { FailToast } from 'components/elements/Toast'

export default {
  updateProfile: async (data: ProfileData): Promise<ProfileData> => {
    const res = await axios.post(`${MAIN_URL}/api/profile/onboarding`, data)
    return res.data as ProfileData
  },
  editProfile: async (
    data: ProfileData,
    withPhoto: boolean
  ): Promise<ProfileData> => {
    let res
    if (withPhoto) {
      res = await axios.put(
        `${MAIN_URL}/api/profile/update/${data.username}`,
        data
      )

      return res.data.content
    } else {
      let toSend: any = data
      delete toSend['foto_profil']
      res = await axios.put(
        `${MAIN_URL}/api/profile/update/${data.username}`,
        toSend
      )

      return res.data.content
    }
  },
  loadProfile: async (username: string): Promise<ProfileData> => {
    const res = await axios.get(`${MAIN_URL}/api/profile/${username}`)
    return res.data as ProfileData
  },
  uploadProfilePicture: async (file: File) => {
    const res = await axios.get(`${MAIN_URL}/api/common/generate-put-url`, {
      params: {
        file_name: file.name,
        file_type: file.type
      }
    })
    const file_aws_key = res.data.content[0].file_aws_key
    const preSignedUrl = res.data.content[1].presigned_url

    const customAxios = axios.create()
    const uploadRes = await customAxios.put(preSignedUrl, file, {
      headers: {
        'content-type': file.type
      }
    })
    return file_aws_key
  },
  loadFriendRecommendations: async (): Promise<{
    users: FriendRecommendation[]
  }> => {
    const res = await axios.get(`${MAIN_URL}/api/interest/recomendation?page=1`)
    return res.data
  },
  sendInvitation: async (
    data: SendInvitationPayload
  ): Promise<{ message: string }> => {
    try {
      const res = await axios.post(
        `${MAIN_URL}/api/invitation/create_invitation`,
        data
      )
      return res.data
    } catch (err: any) {
      FailToast('Failed to send invitation, please try again later')
      return err.data
    }
  },
  getAllInterest: async () => {
    const res = await axios.get(`${MAIN_URL}/api/interest`)
    return res.data
  },
  postInterest: async (data: number[]) => {
    const res = await axios.post(`${MAIN_URL}/api/interest/register`, {
      interest: data
    })
    return res.data
  },
  getBadgeDetails: async (id: string | string[]) => {
    const res = await axios.get(`${MAIN_URL}/api/badge/detail/${id}`)
    return res.data.data
  },
  getBadge: async () => {
    const res = await axios.get(`${MAIN_URL}/api/badge`)
    return res.data.data
  },
  getMessages: async () => {
    const res = await axios.get(`${MAIN_URL}/api/message`)
    return res.data
  }
}
