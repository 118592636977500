import styled from 'styled-components'

interface StyledProps {
  openArrow?: boolean
  hideOnMobile?: boolean
  left?: boolean
  open?: boolean
  active?: boolean
  LogoNav?: boolean
  onClick?: any
  mobileWidth?: string
}

interface NavInterface {
  hasSlug: boolean
}

export const Nav = styled.nav.attrs({
  className:
    'text-base h-14 lg:h-20 fixed z-10 -top-0 w-full bg-primaryWhite flex py-3 lg:py-5 px-6 justify-between items-center'
})<NavInterface>`
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.15));

  @media only screen and (max-width: 1024px) {
    display: ${(props) => (props.hasSlug ? 'none !important' : 'flex')};
  }
`

export const NavLogoWrapper = styled.div.attrs({
  className: 'flex items-center'
})`
  height: inherit;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    justify-content: space-between;
  }
`

export const WrapperHamburger = styled.div.attrs({
  className: 'flex lg:hidden justify-center items-center w-9 h-9'
})<StyledProps>`
  transition: all 0.5s ease-in-out;
  ${(props) =>
    props.open &&
    `
    & > :nth-child(1){
      transform: translateX(-45px);
      background: transparent;
    }
    & > :nth-child(1):before {
      transform: rotate(45deg) translate(30px, -30px);
    }
    & > :nth-child(1):after {
      transform: rotate(-45deg) translate(30px, 30px);
    }
    }
  `}
`

export const HamburgerIcon = styled.div.attrs({
  className: 'w-6 h-1 rounded-full bg-secondSeaBlue-active'
})`
  transition: all 0.5s ease-in-out;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 1.25rem;
    height: 0.25rem;
    border-radius: 9999px;
    background: #005BF8;
    transition: all 0.4s ease-in-out;
  }
  &:before {
    transform: translateY(-8px);
  }
  &:after {
    transform: translateY(8px);
  }
`

export const NavLinkWrapper = styled.div.attrs({
  className:
    'flex flex-col lg:flex-row justify-start lg:justify-end items-center'
})<StyledProps>`
  @media only screen and (max-width: 1024px) {
    position: absolute;
    transition: all 0.5s ease-in-out;
    width: 100vw;
    height: 100vh;
    background: white;
    padding: 1.8rem 1.5rem;
    top: 3.5rem;

    ${({ open }) =>
      open
        ? `
      opacity: 1;
      left: 0;
    `
        : `
      opacity: 0;
      left: 100vw;
    `}
  }
`

export const ProfileDropDown = styled.div.attrs({
  className:
    'w-full lg:w-max flex py-3 lg:py-0 pl-0 lg:pl-9 lg:text-base text-sm'
})<StyledProps>`
  ${(props) =>
    props.openArrow
      ? `& img {
      transition: all .2s ease-in;
      transform: rotate(180deg);
    }`
      : `& img {
      transition: all .2s ease-in;
      transform: rotate(0deg);
    }`}
  cursor: pointer;
  color: #828282;
`

export const StyledLink = styled.a.attrs({
  className: 'lg:text-base text-sm hover:text-secondSeaBlue-active'
})<StyledProps>`
  padding: 0 2rem;
  ${(props) =>
    props.active
      ? `
      color: #005BF8;
      font-weight: 600;
    `
      : `
      color: #828282;
      font-weight: normal;
    `}
  ${({ LogoNav }) => LogoNav && `padding: 0`};

  @media only screen and (max-width: 1024px) {
    ${({ LogoNav }) => !LogoNav && `width: 100%;`};
    margin: 0.75rem 0;
    padding: 0;
    text-align: left;
  }
`

export const StyledImg = styled.img<StyledProps>`
  margin-right: ${(props) => props.left && '13px'};
  object-fit: contain;
  width: ${({ width }) => width};
  @media only screen and (max-width: 768px) {
    ${(props) => props.hideOnMobile && `display: none;`}
    width: ${({ mobileWidth }) => mobileWidth}
  }
`

export const FloatingDiv = styled.div.attrs({
  className:
    'relative lg:absolute rounded-lg flex w-full lg:w-max flex-col bg-primaryWhite'
})<StyledProps>`
  top: 110%;
  right: 116px;
  transition: all 0.2s ease-in-out;
  opacity: ${(props) => (props.openArrow ? '1' : '0')};

  & a {
    padding: 0;
  }

  @media only screen and (max-width: 1024px) {
    top: 0;
    left: 1rem;
    & a {
      border-bottom: 0px;
    }
  }
`
