import { createSlice } from '@reduxjs/toolkit'

const invitations = createSlice({
  name: 'invitations',
  initialState: {
    received: [],
    sent: [],
    isLoading: false,
  },

  reducers: {
    setSentInvitations(state, { payload }){
      if(payload.length !== 0){
        // @ts-ignore
        state.sent = [...state.sent, ...payload]
      }
      return state
    },
    setReceivedInvitations(state, { payload }){
      if(payload.length !== 0) {
        // @ts-ignore
        state.received = [...state.received, ...payload]
      }
      return state
    },
    setAcceptReceivedInvitations(state, { payload }){
      state.received = state.received.filter(item => item['sender'] != payload)
      return state
    },
    setLoading(state) {
      state.isLoading = !state.isLoading
      return state
    },
  }
})

export const { setSentInvitations, setReceivedInvitations, setAcceptReceivedInvitations, setLoading } = invitations.actions

export default invitations.reducer