const SSO_UI_URL = "https://sso.ui.ac.id/cas2";
const DOMAIN = (): string => {
  if (typeof window !== "undefined") {
    return window.location.origin;
  } 
  return ""
}

const loginUrl = `${SSO_UI_URL}/login?service=${DOMAIN()}/`;
const logoutUrl = `${SSO_UI_URL}/logout?url=${DOMAIN()}/`;

export default {
  redirectToSSOLogin: () => {
    if (typeof window !== "undefined") {
      window.location.replace(loginUrl);
    }
  },
  redirectToSSOLogout: () => {
    if (typeof window !== "undefined") {
      window.location.replace(logoutUrl);
    }
  }
}