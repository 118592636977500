import React from 'react'
import Button from 'components/elements/Button'

interface PopupProps {
  cancelOnclick: (event: React.MouseEvent<HTMLButtonElement>) => void
  confirmOnclick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const Popup: React.FC<PopupProps> = ({ cancelOnclick, confirmOnclick }) => {
  return (
    <div
      className="fixed top-1/2 left-1/2 w-full h-full z-10 bg-black bg-opacity-50 flex flex-col items-center justify-center"
      style={{ transform: 'translate(-50%, -50%)' }}
    >
      <div className="bg-white p-5 flex flex-col items-center justify-center border-1 border-greyPMB rounded-md">
        Are you sure you want to log out?
        <div className="flex flex-row items-center justify-center mt-4">
          <Button type="secondary" additionStyle="mr-3" onClick={cancelOnclick}>
            Cancel
          </Button>
          <Button type="warningReversed" onClick={confirmOnclick}>
            Log out
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Popup
