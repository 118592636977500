import config from 'api/config'
import axios from 'axios'

import { PuzzleAnswers } from 'types/puzzle'

export default {
  getPuzzles: (page: Number=1, limit :Number=2) => {
    return axios.get(`${config}/api/puzzle?page=${page}&limit=${limit}`)
  },
  getPuzzle: (id: number) => {
    return axios.get(`${config}/api/puzzle/${id}`)
  },
  postPuzzle: (payload: PuzzleAnswers) => {
    return axios.post(`${config}/api/puzzle/submit`, payload)
  },
  putPuzzle: (payload: PuzzleAnswers) => {
    return axios.put(`${config}/api/puzzle/update`, payload)
  },
  uploadPuzzlePhoto: async (picture: File) => {
    const res = await axios.get(`${config}/api/common/generate-put-url`, {
      params: {
        file_name: picture.name,
        file_type: picture.type
      }
    })

    const data = res.data.content
    const fileAwsKey = data[0].file_aws_key
    const presignedUrl = data[1].presigned_url

    const instance = axios.create()
    await instance.put(presignedUrl, picture, {
      headers: {
        'content-type': picture.type
      }
    })

    return fileAwsKey
  }
}
