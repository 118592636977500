import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import React from 'react'
import useStore from 'redux/store'
import Head from 'next/head'
import Layout from 'components/common/layout'

import '../styles/globals.css'
import 'tailwindcss/tailwind.css'
import { ChakraProvider } from '@chakra-ui/react'
import type, { AppProps } from 'next/app'

function MyApp({ Component, pageProps }: AppProps) {
  const { store, persistor } = useStore()

  const desc =
    'PMB merupakan kegiatan untuk mahasiswa baru agar dapat menjalani perkuliahan di Fasilkom. PMB memiliki rangkaian acara yang membawakan nilai-nilai dan budaya yang akan ditanam untuk mahasiswa baru nantinya'
  const PMBtitle = 'PMB Fasilkom UI 2022'

  return (
    <Provider store={store}>
      <ChakraProvider>
        <Head>
          <title>PMB 2022</title>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width"
          />
          {/* <!-- Open Graph / Facebook --> */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://pmb.cs.ui.ac.id" />
          <meta property="og:title" content={PMBtitle} />
          <meta property="og:description" content={desc} />
          <meta
            property="og:image"
            content="/favicon/android-chrome-512x512.png"
          />

          {/* <!-- Twitter --> */}
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://pmb.cs.ui.ac.id" />
          <meta property="twitter:title" content={PMBtitle} />
          <meta property="twitter:description" content={desc} />
          <meta
            property="twitter:image"
            content="/favicon/android-chrome-512x512.png"
          />

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/favicon/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon/favicon-16x16.png"
          />
          <link rel="manifest" href="/favicon/site.webmanifest" />
          <link
            rel="mask-icon"
            href="/favicon/safari-pinned-tab.svg"
            color="#5bbad5"
          />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
        </Head>
        <PersistGate persistor={persistor}>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </PersistGate>
      </ChakraProvider>
    </Provider>
  )
}
export default MyApp
